import { Component, Input, OnInit } from "@angular/core";
import { ExperienceLoadService } from '../../services/experience-load/experience-load.service';

@Component({
    moduleId: module.id,
    selector: 'experience',
    templateUrl: 'experience.component.html',
    styleUrls: ['experience.component.css']
})
export class ExperienceComponent {
    constructor(private _experienceLoadService: ExperienceLoadService) {

    }

    experienceList: Array<any> = [];
    @Input() experienceTitle: string;

    ngOnInit() {
        this._experienceLoadService.loadExperience("main-experience").subscribe(
            (data) => {
                this.experienceList = data;
            },
            (err) => console.error("[ExperienceComponent::ngOnInit > _experienceLoadService.loadSkills('main-experience')]: Error: ", err)
        );
    }
}
