import { Component, Input } from "@angular/core";

@Component({
    moduleId: module.id,
    selector: 'experience-group',
    templateUrl: 'experience-group.component.html',
    styleUrls: ['experience-group.component.css']
})
export class ExperienceGroupComponent {
    constructor() {

    }

    @Input() experienceGroupTitle: string;
    @Input() experienceList: Array<any>;

}
