import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { ReplaySubject } from 'rxjs';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/share'

const SKILLS_URL = "assets/skill-sets/";
const SKILLS_FILE_EXT = ".json";

@Injectable()
export class SkillsLoadService {

  private cachedData: ReplaySubject<any> = new ReplaySubject(1);

  constructor(private http: Http) {

  }

  public loadSkills(skillList: string, forceRefresh: boolean = false): ReplaySubject<any> {
    if (forceRefresh || this.cachedData.observers.length === 0) {
      this.http.get(SKILLS_URL + skillList + SKILLS_FILE_EXT)
      .share()
      .map(res => res.json())
      .subscribe(
        (skillsJSON: any) => {
          this.cachedData.next(skillsJSON.skillSets)
        },
        (error) => {
            this.cachedData.error(error);
            this.cachedData = new ReplaySubject(1);
        }
      )
    };
    return this.cachedData;
  }

  private handleError(err) {
    console.error("SkillsLoadService::handleError(): Error: ", err);
  }
}
