import { Component, Input, ViewChild, ElementRef } from "@angular/core";
import { Observable } from 'rxjs';
import 'rxjs/add/observable/timer'

import { JSUIEngine } from "js-ui-engine-ngx-wrapper";

const MathTAU = (Math.PI * 2);

@Component({
    moduleId: module.id,
    selector: 'matrix-background',
    templateUrl: 'matrix-background.component.html',
    styleUrls: ['matrix-background.component.css']
})
export class MatrixBackgroundComponent {

    @Input() drawScale: number = 1;
    @Input() fontSize: number = 0.01;
    @Input() fontStyle: string = "Bold Megrim"; // "Megrim";
    @Input() animating: boolean = true;
    @Input() backgroundSrc: string = "";
    @Input() characterSize: number = 10;
    @Input() chainTimer: number = 60;
    @Input() chainTopXPercent: number = 35;
    @Input() chainCount: number = 35;

    @Input() showBackgroundImage: boolean = true;
    @Input() showMatrixAnimation: boolean = true;

    private jsuiEngine: JSUIEngine;
    private ctx: any;
    private canvasSize: Array<number> = [-1, -1];
    private lastAnimationTime: number = -1;

    private letterChains = [];

    private backgroundImage: any = null;
    private backgroundImageLoaded = false;

    @ViewChild("backgroundAnimationCanvas") backgroundAnimationCanvas: ElementRef;
    @ViewChild("backgroundAnimationCanvasContainer") backgroundAnimationCanvasContainer: ElementRef;

    constructor() {
        this.jsuiEngine = new JSUIEngine();
    }

    ngAfterContentInit() {
        this.backgroundImage = new Image();
        this.backgroundImage.onload = () => {
            this.backgroundImageLoaded = true;
        };
        this.backgroundImage.src = this.backgroundSrc;

        this.ctx = this.jsuiEngine.setupCanvas(this.backgroundAnimationCanvas.nativeElement);
        this.ctx.imageSmoothingEnabled = true;
        
        this.animateLoop();
    }

    public startAnimation () {
        if (!this.animating) {
            this.animating = true;
            this.animateLoop()
        }
    }

    private animateLoop() {
        let currentTime: number = new Date().getTime();
        let timeDiff: number = (this.lastAnimationTime - this.lastAnimationTime);
        let fillRatePixels: number = ((timeDiff / 1000) * 0.75);

        this.detectCanvasSize();

        if (this.letterChains.length < this.chainCount) {
            let newPosX: number = this.randomNumber(1, 100);
            let newPosY: number = this.randomNumber(this.chainTopXPercent, 100);
            let chainSize: number = this.randomNumber(5, 30);
            let startTime: number = this.randomNumber(500, 2500);
            newPosX /= 100;
            newPosY /= 100;
            this.createChain(newPosX, newPosY, chainSize, startTime);
        }

        if (this.animating) {
            requestAnimationFrame(() => { this.animateLoop(); });
        }

        this.lastAnimationTime = currentTime;
        
        if (this.jsuiEngine) {
            this.jsuiEngine.refreshScreen();
        }
    }

    private createChain(x: number = -1, y: number = -1, l: number = 25, st: number = 1000) {
        let timerChain = Observable.timer(st, this.chainTimer);
        let localChain = [];
        let myID: string = this.uuidv4();
        let currentAlpha: number = this.randomNumber(1, 3);

        this.letterChains.push({"chainID": myID, "chainObject": localChain});

        let chainSubscription = timerChain.subscribe(() => {
            if (currentAlpha > 1) {
                localChain.map((link) => {
                    if (this.randomNumber(1, 75) === 10) {
                        link.text = this.randomChar();
                    }
                });
            }

            if (localChain.length < l) {
                let newChainLink: any = this.createChainLink(x, y, localChain.length);
                localChain.map((link) => {
                    link.render = (self) => {
                        this.jsuiEngine.drawText(self.x, self.y, self.text, self, null, this.jsuiEngine.canvasContext, {'fillStyle': "#11BB00"});
                    }
                });
                localChain.push(newChainLink);
            } else {
                localChain.map((link) => {
                    link.render = (self) => {
                        if (currentAlpha < 1) {
                            this.jsuiEngine.drawText(self.x, self.y, self.text, self, null, this.jsuiEngine.canvasContext, {'fillStyle': "rgba(153, 255, 153, " + currentAlpha + ")"});
                        } else {
                            this.jsuiEngine.drawText(self.x, self.y, self.text, self, null, this.jsuiEngine.canvasContext, {'fillStyle': "rgba(17, 187, 0, " + currentAlpha + ")"});
                        }
                        currentAlpha -= 0.0015;
                    }
                });
                if (currentAlpha < 0) {
                    chainSubscription.unsubscribe();
                    localChain = [];
                    this.letterChains = this.letterChains.filter((chain) => {
                        return chain.chainID !== myID;
                    });
                }
            }
        });
    }

    private createChainLink(x: number, y: number, newLinkIndex: number) {
        return {
            'x': this.relToAbs(x, 0),
            'y': this.relToAbs(y, 1) + (newLinkIndex * this.characterSize),
            'text': this.randomChar(),
            'shape': 'text',
            'render': (self) => {
                this.jsuiEngine.drawText(self.x, self.y, self.text, self, null, this.jsuiEngine.canvasContext, {'fillStyle': "#99FF99"});
            },
            'visible':true
        }
    }

    private setupObjects() {
        this.jsuiEngine.canvasObjects = [];

        let objController: any = this;
        if (this.showBackgroundImage) {
            if (this.backgroundImageLoaded) {
                this.jsuiEngine.canvasObjects.push({
                    "x": 0,
                    "y": this.relToAbs(-0.21, 1),
                    "w": this.relToAbs(1, 0),
                    "h": this.relToAbs(1, 0),
                    "sw": this.relToAbs(1, 0),
                    "sh": this.relToAbs(1.4, 1),
                    "src": {objImage: this.backgroundImage},
                    "shape":"image",
                    "render": function(self) {
                        objController.jsuiEngine.drawImage(self.src, self.x, self.y, self.sw, self.sh, null, null, self.w, self.h, objController.jsuiEngine.canvasContext);
                    },
                    "visible":true
                  });
            }
        }

        if (this.showMatrixAnimation) {
            this.letterChains.map((chain) => {
                chain.chainObject.map((link) => {
                    this.jsuiEngine.canvasObjects.push(link);
                });
            });
        }
    }

    private randomNumber(lowerNumber: number, higherNumber: number) {
        return Math.floor(Math.random() * higherNumber) + lowerNumber;
    }

    private randomChar() {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        possible += "あいうえおかきくけこさしすせそたちつてとなにぬねのは";
        possible += "アイウエオカキクケコサシスズセソタチツテトナニヌネノハ";
        possible += "バヒフヘホマミムメモヤユヨラリルレロヮワヰヱヲンヴヵヶヷヸヹヺーヽヾヿ";

        return possible.charAt(Math.floor(Math.random() * possible.length));

    }

    private relToAbs(relCoord: number, dimension: number) {
        return (relCoord * this.canvasSize[dimension]);
    }

    private uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
    }

    private detectCanvasSize() {
        this.backgroundAnimationCanvas.nativeElement.width = this.backgroundAnimationCanvasContainer.nativeElement.clientWidth;
        this.backgroundAnimationCanvas.nativeElement.height = this.backgroundAnimationCanvasContainer.nativeElement.clientHeight;

        this.canvasSize = [this.backgroundAnimationCanvas.nativeElement.width, this.backgroundAnimationCanvas.nativeElement.height];

        if (this.ctx) {
            this.ctx.font = (this.relToAbs(this.fontSize, 1) * this.drawScale) + 'px ' + this.fontStyle;
            this.setupObjects();
        }
    }

    ngOnDestroy() {
        delete this.jsuiEngine;
    }

}
