import { Component, Input } from "@angular/core";
import { Angulartics2 } from 'angulartics2';

@Component({
    moduleId: module.id,
    selector: 'bottom-area',
    templateUrl: 'bottom-area.component.html',
    styleUrls: ['bottom-area.component.css']
})
export class BottomAreaComponent {
    constructor(private _angulartics2: Angulartics2) {

    }

    @Input() bottomAreaTitle: string;
    @Input() githubLabelText: string;
    @Input() linkedInLabelText: string;
    @Input() emailLabelText: string;
    @Input() mapsLabelText: string;

    mouseEnterSet(event: any, socialName: string) {
        this._angulartics2.eventTrack.next({
            action: 'mouseOverSocial', 
            properties: { category: 'userTriggeredEvent', label: socialName },
        });
    }

    mouseClickSet(event: any, socialName: string) {
        this._angulartics2.eventTrack.next({
            action: 'mouseClickSocial', 
            properties: { category: 'userTriggeredEvent', label: socialName },
        });
    }

}
