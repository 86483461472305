import { Component, Input } from "@angular/core";

@Component({
    moduleId: module.id,
    selector: 'profile',
    templateUrl: 'profile.component.html',
    styleUrls: ['profile.component.css']
})
export class ProfileComponent {
    constructor() {

    }

    @Input() aboutMeTitle: string;
    @Input() aboutMeSubTitle: string;
    @Input() descriptionTitle: string;
    @Input() descriptionText: string;
    @Input() detailsTitle: string;
    @Input() ageTitle: string;
    @Input() ageText: string;
    @Input() locationTitle: string;
    @Input() locationText: string;
    @Input() citizenshipTitle: string;
    @Input() citizenshipText: string;
    @Input() languagesTitle: string;
    @Input() languagesText: string;

}
