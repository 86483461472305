import { Component, OnInit, isDevMode } from "@angular/core";
import { HeroComponent } from '../../components/hero/hero.component';

@Component({
    selector: 'default',
    templateUrl: 'default-page.component.html',
    styleUrls: ['default-page.component.css']
})

export class DefaultPageComponent implements OnInit {

    constructor() {

    }

    ngOnInit() {

    }
}
