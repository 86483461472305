import { Component, Input } from "@angular/core";

@Component({
    moduleId: module.id,
    selector: 'section-title',
    templateUrl: 'section-title.component.html',
    styleUrls: ['section-title.component.css']
})
export class SectionTitleComponent {
    constructor() {

    }

    @Input() sectionTitleText: string;
    @Input() sectionSubTitleText: string = "";

}
