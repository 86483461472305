import { Component, Input } from "@angular/core";

@Component({
    moduleId: module.id,
    selector: 'space-divider',
    templateUrl: 'space-divider.component.html',
    styleUrls: ['space-divider.component.css']
})
export class SpaceDividerComponent {
    constructor() {

    }
}
