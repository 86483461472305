import { Component, Input } from "@angular/core";
import { LanguageLoadService } from '../../services/language-load/language-load.service';
import { LanguageListLoadService } from '../../services/language-list-load/language-list-load.service';
import { Angulartics2 } from 'angulartics2';

@Component({
    moduleId: module.id,
    selector: 'language-selector',
    templateUrl: 'language-selector.component.html',
    styleUrls: ['language-selector.component.css']
})
export class LanguageSelectorComponent {

    languageList: Array<any> = [];
    currentLanguage: number = 0;
    menuExpanded: boolean = false;

    constructor(private _languageService: LanguageLoadService, private _languageListLoadService: LanguageListLoadService, private _angulartics2: Angulartics2) {

    }

    ngOnInit() {
        this._languageListLoadService.loadLanguageList("language-select").subscribe(
            (data) => {
                this.languageList = data;
                for (let i = 0; i < data; i++) {
                    if (data[i].default) {
                        this.changeLanguage(null, data[i].languageResourceUrl, i);
                        this.currentLanguage = i;
                    }
                }
            },
            (err) => console.error("[LanguageSelectorComponent::ngOnInit > _languageListLoadService.loadLanguageList('language-select')]: Error: ", err)
        );
    }

    public expandMenu(event: any, expand: boolean = true) {
        this._angulartics2.eventTrack.next({
            action: 'expandMenu', 
            properties: { category: 'userTriggeredEvent', label: expand },
        });
        this.menuExpanded = expand;
    }

    public changeLanguage(event: any, newLanguage: string, languageIndex: number): void {
        this._angulartics2.eventTrack.next({
            action: 'changeLanguage', 
            properties: { category: 'userTriggeredEvent', label: newLanguage },
        });
        this.menuExpanded = false;
        this.currentLanguage = languageIndex;
        this._languageService.loadLanguage(newLanguage, true).subscribe();
    }

}
