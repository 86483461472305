import { Component, Input, OnInit } from "@angular/core";
import { SkillsLoadService } from '../../services/skills-load/skills-load.service';

@Component({
    moduleId: module.id,
    selector: 'skills',
    templateUrl: 'skills.component.html',
    styleUrls: ['skills.component.css']
})
export class SkillsComponent implements OnInit {

    skillsList: Array<any> = [];

    constructor(private _skillsLoadService: SkillsLoadService) {

    }

    ngOnInit() {
        this._skillsLoadService.loadSkills("main-skills").subscribe(
            (data) => {
                this.skillsList = data;
            },
            (err) => console.error("[SkillsComponent::ngOnInit > _skillsLoadService.loadSkills('main-skills')]: Error: ", err)
        );
    }

    @Input() skillsTitle: string;


}
