import { Component, Input } from "@angular/core";
import { Angulartics2 } from 'angulartics2';

@Component({
    moduleId: module.id,
    selector: 'experience-set',
    templateUrl: 'experience-set.component.html',
    styleUrls: ['experience-set.component.css']
})
export class ExperienceSetComponent {
    constructor(private _angulartics2: Angulartics2) {
        
    }

    @Input() experienceGroupTitle: string;
    @Input() fromTime: string;
    @Input() toTime: string;
    @Input() titleAtExperience: string;
    @Input() location: string = "";
    @Input() description: string;

    mouseEnterSet(event: any, experienceName: string) {
        this._angulartics2.eventTrack.next({
            action: 'mouseOverExperience', 
            properties: { category: 'userTriggeredEvent', label: experienceName },
        });
    }

    mouseLeaveSet(event: any, experienceName: string) {
        this._angulartics2.eventTrack.next({
            action: 'mouseOutExperience', 
            properties: { category: 'userTriggeredEvent', label: experienceName },
        });
    }

    mouseClickSet(event: any, experienceName: string) {
        this._angulartics2.eventTrack.next({
            action: 'mouseClickExperience', 
            properties: { category: 'userTriggeredEvent', label: experienceName },
        });
    }
}
