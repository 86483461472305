import { Pipe, PipeTransform, isDevMode  } from '@angular/core';
import { Observable } from 'rxjs';
import { LanguageLoadService } from '../../services/language-load/language-load.service';

@Pipe({name: 'langAsset'})
export class LangAsset implements PipeTransform {

  constructor(private languageService: LanguageLoadService) {
    
  }

  transform(textKey: string): Observable<any> {
    return new Observable(observer => {
      this.languageService.loadLanguage("en-CA").subscribe((langData) => {
        if(isDevMode()) {
          observer.next(langData[textKey] ? langData[textKey] : 'Error: ' + textKey);
        } else {
          observer.next(langData[textKey] ? langData[textKey] : textKey);
        }
      })
    });
  }
}
