import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Http } from '@angular/http';

const LANGUAGE_LIST_URL = "assets/language-list/";
const LANGUAGE_LIST_FILE_EXT = ".json";

@Injectable()
export class LanguageListLoadService {

  private cachedData: ReplaySubject<any> = new ReplaySubject(1);

  constructor(private http: Http) {

  }

  public loadLanguageList(languageList: string, forceRefresh: boolean = false): ReplaySubject<any> {
    if (forceRefresh || this.cachedData.observers.length === 0) {
      this.http.get(LANGUAGE_LIST_URL + languageList + LANGUAGE_LIST_FILE_EXT)
      .share()
      .map(res => res.json())
      .subscribe(
        (languageListJSON: any) => {
          this.cachedData.next(languageListJSON.languageList)
        },
        (error) => {
            this.cachedData.error(error);
            this.cachedData = new ReplaySubject(1);
        }
      )
    };
    return this.cachedData;
  }

  private handleError(err) {
    console.error("LanguageListLoadService::handleError(): Error: ", err);
  }
}
