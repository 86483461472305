import { Component, Input, HostListener, ViewChild, ElementRef } from "@angular/core";
import { MatrixBackgroundComponent } from '../matrix-background/matrix-background.component'

@Component({
    moduleId: module.id,
    selector: 'hero-component',
    templateUrl: 'hero.component.html',
    styleUrls: ['hero.component.css']
})
export class HeroComponent {

    private animateBackground = true;
    @ViewChild("matrixBackgroundContainer") matrixBackgroundContainer: MatrixBackgroundComponent;

    private toggleAnimation: boolean = true;
    private togglePicture: boolean = true;

    @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
        if (window.pageYOffset > 300) {
            this.animateBackground = false;
        } else {
            this.matrixBackgroundContainer.startAnimation();
            this.animateBackground = true;
        }
    }

    constructor() {
    }

    @Input() title: string;
    @Input() subtitle: string;
    @Input() backgroundImageUrl: string = "";

    private toggleBackground() {
        this.togglePicture = !this.togglePicture;
    }

    private toggleMatrix() {
        this.toggleAnimation = !this.toggleAnimation;
    }


}

