import { Component, Input, AfterViewInit } from "@angular/core";
import { Angulartics2 } from 'angulartics2';

@Component({
    moduleId: module.id,
    selector: 'skill-set',
    templateUrl: 'skill-set.component.html',
    styleUrls: ['skill-set.component.css']
})
export class SkillSetComponent {
    constructor(private _angulartics2: Angulartics2) {

    }

    @Input() skillSetName: string;
    @Input() skillSetMax: number = 5;
    @Input() skillSetAt: number = 0;

    starsList: Array<boolean> = [];

    ngAfterViewInit() {

        let filledStars = 0;

        if (this.skillSetAt > this.skillSetMax) {
            filledStars = this.skillSetMax;
        } else {
            filledStars = this.skillSetAt;
        }

        for (let i = 0; i < filledStars; i++) {
            this.starsList.push(true);
        }

        for (let j = 0; j < (this.skillSetMax - this.skillSetAt); j++) {
            this.starsList.push(false);
        }
    }

    mouseEnterSet(event: any, skillName: string) {
        this._angulartics2.eventTrack.next({
            action: 'mouseOverSkill', 
            properties: { category: 'userTriggeredEvent', label: skillName },
        });
    }
}
