import { BrowserModule } from '@angular/platform-browser';
import { NgModule, enableProdMode } from '@angular/core';
import { RouterModule } from "@angular/router";
import { HttpModule } from '@angular/http';

import { AppComponent } from './app.component';

import { DefaultPageComponent } from "./pages/default/default-page.component";
import { HeroComponent } from './components/hero/hero.component';
import { ProfileComponent } from './components/profile/profile.component';
import { SkillsComponent } from './components/skills/skills.component';
import { SkillSetComponent } from './components/skill-set/skill-set.component';
import { SkillGroupComponent } from './components/skill-group/skill-group.component';
import { SpaceDividerComponent } from './components/space-divider/space-divider.component';
import { SectionTitleComponent } from './components/section-title/section-title.component';
import { ExperienceComponent } from './components/experience/experience.component';
import { ExperienceGroupComponent } from './components/experience-group/experience-group.component';
import { ExperienceSetComponent } from './components/experience-set/experience-set.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { BottomAreaComponent } from './components/bottom-area/bottom-area.component';
import { FloatingMenuComponent } from './components/floating-menu/floating-menu.component';
import { MatrixBackgroundComponent } from './components/matrix-background/matrix-background.component';

import { LanguageLoadService } from './services/language-load/language-load.service';
import { LanguageListLoadService } from './services/language-list-load/language-list-load.service';
import { SkillsLoadService } from './services/skills-load/skills-load.service';
import { ExperienceLoadService } from './services/experience-load/experience-load.service';
import { LangAsset } from './pipes/language/language.pipe';

import { Angulartics2Module } from "angulartics2";
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';

import { AngularFontAwesomeModule } from 'angular-font-awesome';

enableProdMode();

@NgModule({
  declarations: [
    AppComponent,
    DefaultPageComponent,
    HeroComponent,
    ProfileComponent,
    SkillsComponent,
    SkillSetComponent,
    SkillGroupComponent,
    SpaceDividerComponent,
    SectionTitleComponent,
    ExperienceComponent,
    LanguageSelectorComponent,
    FloatingMenuComponent,
    ExperienceSetComponent,
    ExperienceGroupComponent,
    MatrixBackgroundComponent,
    BottomAreaComponent,
    LangAsset
  ],
  imports: [
    AngularFontAwesomeModule,
    BrowserModule,
    HttpModule,
    RouterModule.forRoot(
      [
          {path: '**', redirectTo: 'home', pathMatch: 'full'},
          {path: 'home', component: DefaultPageComponent}
      ]),
      Angulartics2Module.forRoot()
  ],
  providers: [
    LanguageLoadService,
    ExperienceLoadService,
    LanguageListLoadService,
    SkillsLoadService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
