import { Component, Input, HostListener } from "@angular/core";
import { Angulartics2 } from 'angulartics2';

@Component({
    moduleId: module.id,
    selector: 'floating-menu',
    templateUrl: 'floating-menu.component.html',
    styleUrls: ['floating-menu.component.css']
})
export class FloatingMenuComponent {

    hasScrolled: boolean = false;
    hasLogged: boolean = false;

    @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
        if (window.pageYOffset > 300) {
            if (!this.hasLogged) {
                this.hasLogged = true;
                this._angulartics2.eventTrack.next({
                    action: 'scrollDownManual', 
                    properties: { category: 'userTriggeredEvent', label: window.pageYOffset },
                });
            }
            
            this.hasScrolled = true;
        } else {
            this.hasScrolled = false;
        }
    }

    constructor(private _angulartics2: Angulartics2) {
    }

    scrollDown(): void {
        this._angulartics2.eventTrack.next({
            action: 'scrollDownClick', 
            properties: { category: 'userTriggeredEvent', label: 'scrolled' },
        });
        if (window.scroll) {
            try {
                window.scroll({ top: window.innerHeight, left: 0, behavior: 'smooth' });
            } catch (e) {
                let before = window.pageYOffset;

                window.scrollTo(0, window.innerHeight);
                
                if (before === window.pageYOffset) {
                    console.warn("Your browser doesn't seem to support scrolling.");
                }
            }
        } else {
            window.scrollTo(0, window.innerHeight);
        }
        
    }

    @Input() title: string;
}
