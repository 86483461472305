import { Component, Input } from "@angular/core";

@Component({
    moduleId: module.id,
    selector: 'skill-group',
    templateUrl: 'skill-group.component.html',
    styleUrls: ['skill-group.component.css']
})
export class SkillGroupComponent {
    constructor() {

    }

    @Input() skillGroupTitle: string;
    @Input() skillList: any;

}
