import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { ReplaySubject } from 'rxjs';

const LANGUAGE_URL = "assets/languages/";
const LANGUAGE_FILE_EXT = ".json";

@Injectable()
export class LanguageLoadService {

  private cachedData: ReplaySubject<any> = new ReplaySubject(1);

  constructor(private http: Http) {

  }

  public loadLanguage(languageName: string, forceRefresh: boolean = false): ReplaySubject<any> {
    if (forceRefresh || this.cachedData.observers.length === 0) {
      this.http.get(LANGUAGE_URL + languageName + LANGUAGE_FILE_EXT)
      .map(res => res.json())
      .subscribe(
        (languageJSON: any) => {
          this.cachedData.next(languageJSON.languageData)
        },
        (error) => {
            this.cachedData.error(error);
            this.cachedData = new ReplaySubject(1);
        }
      )
    };
    return this.cachedData;
  }

  private handleError(err) {
    console.error("LanguageLoadService::handleError(): Error: ", err);
  }
}
